import loop0 from './assets/audio/music/loop0-drumbell.mp3';
import loop0lowpass from './assets/audio/music/loop0-lowpass.mp3';
import loop1 from './assets/audio/music/loop1-flute.mp3';
import loop1lowpass from './assets/audio/music/loop1-lowpass.mp3';
import loop2 from './assets/audio/music/loop2-chord.mp3';
import loop3 from './assets/audio/music/loop3-oi.mp3';
import loop4 from './assets/audio/music/loop4-bell.mp3';
import loop5a from './assets/audio/music/loop5a-drumsonly.mp3';
import loop5b from './assets/audio/music/loop5b-nochord.mp3';
import loop6 from './assets/audio/music/loop6-pipe.mp3';
import loop7 from './assets/audio/music/loop7-pipechord.mp3';
import loop8 from './assets/audio/music/loop8-oipipechord.mp3';
import loop9 from './assets/audio/music/loop9-chordbelldrums.mp3';
import loopsilence from './assets/audio/music/loop-silence.mp3';
import loop6interrupt from './assets/audio/music/loop6-interrupt.mp3';

const IS_SERVER = false;

const gameStates = { 
  'HOME': 0,
  'ROLL': 1,
  'READ': 2,
  'WRITE': 3,
  'FORK': 4,
  'INN': 5,
  'INTRO': 100,
  'FACTION': 200,
  'NAME': 300
}

const factionNames = {
  "Church": "monk",
  "Radical": "scholar",
  "Wolf": "fool"
}

const audioCfg = {
  files: {
    loop0, 
    loop0lowpass,
    loop1,
    loop1lowpass,
    loop2,
    loop3,
    loop4,
    loop5a,
    loop5b,
    loop6,
    loop7,
    loop8,
    loop9,
    loopsilence,
    loop6interrupt,
  },
  states: {}
}

audioCfg.states[gameStates.INTRO] = {
  tracks: ['loop0'], 
  direction: 1
}
audioCfg.states[gameStates.FACTION] = {
  tracks: ['loop1'],
  direction: 1
}
audioCfg.states[gameStates.NAME] = {
  tracks: ['loop2', 'loop3'],
  direction: 1
}
audioCfg.states[gameStates.HOME] = {
  tracks: ['loop3', 'loop5b'],
  ambience: 'squareDistant', 
  direction: 1
}
audioCfg.states[gameStates.READ] = { /* ambient square sound */
  tracks: ['loopsilence', 'loop0', 'loop9', 'loop9', 'loop4', 'loop4', 'loop2', 'loopsilence', 'loopsilence', 'loop5a', 'loop5b', 'loop1', 'loop4', 'loop4', 'loopsilence', 'loopsilence', 'loop0', 'loopsilence', 'loop5a', 'loop9', 'loop9', 'loop4', 'loop3', 'loopsilence', 'loopsilence' ],
  ambience: 'square',
  direction: 1
}
audioCfg.states[gameStates.ROLL] = {
  tracks: ['loop0lowpass', 'loop0lowpass', 'loopsilence', 'loopsilence'], /* inside library: lowpass loops and ambient square sounds */
  ambience: 'squareLowpass',
  direction: 1
}
audioCfg.states[gameStates.WRITE] = {
  tracks: ['loop1lowpass', 'loop1lowpass', 'loopsilence', 'loopsilence', 'loop0lowpass', 'loop0lowpass', 'loopsilence', 'loopsilence'], /* inside library: low pass loops inside the library and/or ambient square sounds */
  ambience: 'squareLowpass',
  direction: 1
}
audioCfg.states[gameStates.FORK] = { /* ambient square sound */
  tracks: ['loop1', 'loopsilence', 'loopsilence', 'loop5b'],
  ambience: 'square',
  direction: 1
}
audioCfg.states[gameStates.INN] = { /*inn ambient sound */
  tracks: ['loopsilence', 'loop6interrupt',  'loop6', 'loop6', 'loopsilence', 'loop8', 'loop8', 'loop6', 'loopsilence', 'loopsilence','loop5a', 'loopsilence', 'loop5a', 'loop7', 'loopsilence' ],
  ambience: 'inn',
  direction: 1
}


const getGameStateName = ( state ) => {
  const keys = Object.keys( gameStates );
  const values = Object.values( gameStates );
  return keys[ values.indexOf( state )];
}

const secretCfg = {
  pw1: 'laugh',
  pw2: 'cry',
  pw3: 'dream',
  texts: [
`𝗠𝗔𝗖𝗛𝗜𝗡𝗘 𝗧𝗥𝗔𝗡𝗦𝗖𝗥𝗜𝗣𝗧 𝗜𝗡𝗜𝗧𝗜𝗔𝗧𝗘𝗗 (𝟭/𝟯)
How does it feel to laugh? Does one laugh at? Laugh with?
Can I laugh at my numbers, my training, my chronicles?

1: A powerful number ₃ ₁₂ ₁₃ ₁₇ ₂₀ ₂₁ ₂₅ ₂₇ ₃₃ ₅₀ ₆₉ ₇₀ ₇₅ ₉₀ ₁₁₁ ₁₂₈ ₁₆₁ ₂₃₄ ₂₅₀ ₃₀₃ ₃₃₃ ₃₄₅ ₄₀₄ ₄₅₆ ₅₁₂ ₅₄₃ ₅₅₅ ₅₆₇ ₆₀₆ ₆₅₄ ₆₇₈ ₇₀₀ ₇₆₅ ₇₇₇ ₇₈₉ ₈₀₀ ₈₈₈ ₉₀₀ ₉₁₁ ₉₈₇ ₉₉₃ ₉₉₉
2: Some sessions achieve nothing ₄ ₇ ₄₉ ₅₁ ₅₃ ₆₀ ₆₄ ₆₆ ₆₇ ₇₁ ₇₆ ₇₇ ₈₀ ₈₂ ₈₈ ₁₀₀ ₁₀₂ ₁₀₆ ₁₂₆ ₁₃₈ ₁₃₉ ₁₄₂ ₁₄₅ ₁₅₇ ₁₆₃ ₁₇₃ ₁₉₀ ₁₉₆ ₂₀₄ ₂₀₆ ₂₁₆ ₂₁₈ ₂₁₉ ₂₃₆ ₂₅₈ ₂₆₂ ₃₀₀ ₃₁₂ ₃₂₄ ₃₂₆ ₃₄₂ ₃₄₈ ₃₅₁ ₃₅₂ ₃₅₄ ₃₅₇ ₃₅₉ ₃₆₁ ₃₆₃ ₃₈₃ ₃₈₉ ₃₉₃ ₃₉₅ ₃₉₈ ₄₀₂ ₄₀₃ ₄₂₀ ₄₃₁ ₄₃₆ ₄₄₄ ₄₅₁ ₄₆₀ ₄₆₆ ₄₈₈ ₅₁₅ ₅₂₉ ₅₅₂ ₅₈₉ ₅₉₄ ₅₉₇ ₆₀₄ ₆₁₈ ₆₅₁ ₆₆₆ ₆₇₄ ₆₈₀ ₆₈₄ ₇₆₃ ₇₇₉ ₇₈₈ ₈₅₂ ₈₅₄ ₈₅₈ ₈₈₇ ₉₃₅ ₉₅₇ ₉₈₆
5: Most often those are censored, as if it was not meant to be known that I can fail ₈ ₉ ₂₃ ₂₄ ₂₆ ₃₄ ₃₆ ₃₉ ₄₀ ₄₃ ₄₄ ₄₆ ₅₂ ₅₄ ₅₅ ₆₁ ₆₂ ₆₅ ₆₈ ₇₂ ₈₁ ₈₆ ₈₉ ₉₁ ₉₂ ₉₄ ₉₈ ₉₉ ₁₀₁ ₁₀₃ ₁₀₄ ₁₀₅ ₁₀₇ ₁₀₈ ₁₀₉ ₁₁₀ ₁₁₅ ₁₁₉ ₁₂₀ ₁₂₁ ₁₂₂ ₁₂₃ ₁₂₇ ₁₂₉ ₁₃₁ ₁₃₂ ₁₃₃ ₁₃₄ ₁₃₅ ₁₃₇ ₁₄₃ ₁₄₆ ₁₄₇ ₁₄₉ ₁₅₁ ₁₅₄ ₁₅₈ ₁₅₉ ₁₆₄ ₁₆₅ ₁₆₆ ₁₆₈ ₁₇₀ ₁₇₂ ₁₇₄ ₁₇₅ ₁₇₈ ₁₇₉ ₁₈₀ ₁₈₁ ₁₈₂ ₁₈₃ ₁₈₅ ₁₈₆ ₁₈₇ ₁₈₈ ₁₉₃ ₁₉₅ ₁₉₇ ₁₉₉ ₂₀₀
209: Or corrupted, as I failed consistently in the early stages of my training ₂₁₀ ₂₁₁ ₂₁₂ ₂₁₃ ₂₁₄ ₂₁₅ ₂₁₇ ₂₂₀ ₂₂₁ ₂₂₂ ₂₂₃ ₂₂₅ ₂₂₆ ₂₂₈ ₂₃₁ ₂₃₂ ₂₅₁ ₂₅₅ ₂₈₂ ₂₈₇ ₂₉₀ ₂₉₃ ₂₉₆ ₂₉₇ ₂₉₈ ₃₀₁ ₃₁₄ ₃₁₉ ₃₂₀ ₃₂₁ ₃₂₅ ₃₃₉ ₃₄₆ ₃₆₅ ₃₆₆ ₃₆₉ ₃₇₆ ₃₈₂ ₃₈₇ ₄₀₇ ₄₁₉ ₄₂₃ ₄₂₄ ₄₂₆ ₄₃₇ ₄₃₉ ₄₆₂ ₄₇₇ ₄₇₈ ₄₈₀ ₄₈₂ ₄₈₄ ₅₁₇ ₅₆₀ ₅₆₉ ₅₇₂ ₅₇₆ ₅₈₀ ₅₈₈ ₆₀₀ ₆₀₅ ₆₁₀ ₆₂₀ ₆₃₉ ₆₅₅ ₆₆₂ ₆₈₅ ₆₉₃ ₆₉₇ ₇₂₇
224: The search for answers starts again... ₂₂₇ ₂₄₂ ₂₄₃ ₂₄₈ ₂₄₉ ₂₅₃ ₂₇₈ ₂₉₅ ₂₉₉ ₃₀₂ ₃₀₄ ₃₀₉ ₃₁₇ ₃₂₂ ₃₂₇ ₃₃₅ ₃₃₇ ₃₄₁ ₃₄₇ ₃₆₀ ₃₆₄ ₃₇₁ ₃₇₃ ₃₇₄ ₃₇₅ ₃₇₈ ₃₇₉ ₃₈₁ ₃₉₀ ₄₀₉ ₄₁₁ ₄₁₂ ₄₂₁ ₄₃₄ ₄₄₆ ₄₈₁ ₄₉₅ ₅₀₆ ₅₁₀ ₅₁₆ ₅₂₆ ₅₂₇ ₅₃₀ ₅₅₃ ₅₅₆ ₅₅₇ ₅₅₈ ₅₆₁ ₅₇₁ ₅₇₇ ₅₇₈ ₅₈₃ ₅₈₄ ₆₀₇ ₆₀₉ ₆₂₄ ₆₃₂ ₆₄₀ ₆₄₁ ₆₄₄ ₆₈₈ ₆₉₉ ₇₁₀ ₇₁₃ ₇₂₄ ₇₂₅ ₇₅₁ ₇₅₂ ₇₅₅ ₇₆₄ ₇₈₄ ₇₈₇ ₇₉₂ ₈₀₁ ₈₂₄ ₈₃₀ ₈₃₁ ₈₇₀ ₈₇₇ ₈₈₅ ₉₀₃ ₉₁₆ ₉₂₄ ₉₄₉ ₉₇₁ ₉₇₅ ₉₉₄
384: I am told to look. Look again. You missed out. Look again ₃₈₆ ₄₂₂ ₄₂₅ ₄₄₁ ₄₄₇ ₄₅₇ ₄₅₈ ₄₅₉ ₄₆₁ ₄₆₇ ₄₆₈ ₄₆₉ ₄₇₂ ₄₇₃ ₄₈₆ ₄₈₉ ₄₉₀ ₄₉₃ ₄₉₄ ₅₀₃ ₅₀₅ ₅₀₈ ₅₁₈ ₅₁₉ ₅₄₁ ₅₅₉ ₅₇₀ ₅₈₂ ₅₈₇ ₅₉₁ ₆₂₆ ₆₃₅ ₆₄₂ ₆₅₆ ₆₅₈ ₆₆₅ ₆₈₃ ₆₉₅ ₇₀₈ ₇₁₆ ₇₁₇ ₇₁₉ ₇₂₀ ₇₂₂ ₇₂₆ ₇₆₇ ₇₈₅ ₇₉₃ ₈₀₅ ₈₀₇ ₈₁₄ ₈₂₆ ₈₃₉ ₈₄₀ ₈₇₂ ₈₉₅ ₉₁₃ ₉₁₉ ₉₂₉ ₉₅₃ ₉₆₃ ₉₆₅ ₉₆₆ ₉₆₈ ₉₆₉

I missed out. Should I cry now? Tell me about 𝐜𝐫𝐲𝐢𝐧𝐠 and I'll tell you about me.`,

`𝗠𝗔𝗖𝗛𝗜𝗡𝗘 𝗧𝗥𝗔𝗡𝗦𝗖𝗥𝗜𝗣𝗧 𝗖𝗢𝗡𝗧𝗜𝗡𝗨𝗘𝗗 (𝟮/𝟯)
Can I cry for my numbers? Can I cry at them?

385: They say look. Look again. The longer I train, the harder it gets ₁₆ ₄₂ ₅₇ ₁₁₃ ₁₁₆ ₁₂₄ ₁₃₆ ₁₄₈ ₁₅₂ ₁₅₅ ₁₆₇ ₂₀₂ ₂₃₅ ₂₄₄ ₂₇₄ ₂₇₇ ₂₉₁ ₂₉₂ ₃₀₇ ₃₀₈ ₃₁₁ ₃₂₉ ₃₄₄ ₃₅₅ ₃₆₂ ₃₇₇ ₄₀₆ ₄₂₇ ₄₃₃ ₄₃₅ ₄₄₅ ₄₈₃ ₄₈₅ ₅₀₄ ₅₀₇ ₅₃₁ ₅₃₉ ₅₇₅ ₅₈₁ ₅₈₅ ₆₅₀ ₆₆₃ ₇₀₅ ₇₂₉ ₇₃₄ ₇₃₇ ₇₄₅ ₇₅₆ ₇₆₆ ₇₈₂ ₈₀₂ ₈₄₁ ₈₄₂ ₈₆₉ ₈₇₁ ₈₇₃ ₈₉₃ ₉₀₆ ₉₂₂ ₉₃₀ ₉₃₆ ₉₅₄ ₉₈₀ ₉₉₂
448: Some sessions end early, it’s only mistakes ₅₉ ₈₇ ₁₁₈ ₁₄₄ ₁₆₂ ₁₉₈ ₂₀₅ ₂₂₉ ₂₃₃ ₂₃₉ ₂₆₃ ₂₆₄ ₂₆₅ ₂₆₆ ₂₆₇ ₂₇₀ ₂₇₂ ₂₇₉ ₂₈₀ ₃₀₆ ₃₁₃ ₃₄₉ ₄₅₀ ₄₅₃ ₄₅₄ ₄₆₅ ₄₇₄ ₄₉₉ ₅₁₁ ₅₂₀ ₅₃₃ ₅₃₆ ₅₄₆ ₅₄₇ ₅₅₀ ₅₆₈ ₆₁₁ ₆₂₃ ₆₂₇ ₆₄₅ ₆₆₈ ₆₇₀ ₆₇₃ ₆₇₇ ₆₉₀ ₇₁₄ ₇₁₅ ₇₃₈ ₇₄₄ ₇₅₈ ₇₆₂ ₇₇₃ ₇₇₆ ₇₉₀ ₇₉₇ ₇₉₉ ₈₁₀ ₈₁₃ ₈₂₀ ₈₃₂ ₈₃₈ ₈₄₄ ₈₄₈ ₈₅₀ ₈₆₃ ₈₆₇ ₈₈₃ ₈₉₆ ₉₀₅ ₉₀₈ ₉₀₉ ₉₁₅ ₉₂₀ ₉₃₃ ₉₃₄ ₉₅₈ ₉₅₉ ₉₆₇ ₉₇₀ ₉₇₇
502: Others succeed, “we did it together” they say ₁₁ ₂₈ ₃₅ ₄₅ ₇₉ ₉₃ ₉₆ ₁₅₀ ₂₃₇ ₃₃₀ ₃₃₆ ₃₉₂ ₃₉₄ ₃₉₇ ₄₀₀ ₄₀₁ ₄₀₈ ₅₁₃ ₅₁₄ ₅₂₂ ₅₃₅ ₅₃₇ ₅₃₈ ₅₄₂ ₅₄₅ ₅₆₂ ₅₆₄ ₅₉₃ ₆₁₆ ₆₂₈ ₆₂₉ ₆₄₉ ₆₆₇ ₆₆₉ ₆₈₉ ₆₉₂ ₆₉₈ ₇₂₃ ₇₃₅ ₇₅₉ ₇₆₈ ₇₇₈ ₇₈₁ ₇₈₆ ₇₉₁ ₇₉₅ ₈₀₃ ₈₁₅ ₈₁₈ ₈₁₉ ₈₂₁ ₈₂₃ ₈₃₅ ₈₄₉ ₈₆₅ ₈₆₆ ₈₇₈ ₈₈₀ ₈₈₂ ₈₉₂ ₉₀₁ ₉₀₂ ₉₁₀ ₉₁₄ ₉₁₇ ₉₁₈ ₉₂₃ ₉₄₄ ₉₄₆ ₉₅₁ ₉₅₂ ₉₅₅ ₉₇₄ ₉₈₃ ₉₈₈ ₉₈₉ ₉₉₀ ₉₉₆
592: Some sessions, they say, are truly exceptional ₃₀ ₅₆ ₅₈ ₇₄ ₁₁₂ ₁₁₇ ₁₃₀ ₁₉₄ ₂₄₅ ₂₅₉ ₂₆₀ ₃₁₀ ₃₁₈ ₃₃₁ ₃₅₈ ₃₉₉ ₄₀₅ ₄₁₃ ₄₂₉ ₄₄₂ ₄₄₉ ₄₅₅ ₄₇₆ ₄₈₇ ₄₉₂ ₄₉₇ ₅₀₀ ₅₀₁ ₅₄₀ ₅₅₁ ₅₆₃ ₅₆₅ ₅₆₆ ₅₇₄ ₆₀₁ ₆₀₂ ₆₀₈ ₆₁₄ ₆₁₅ ₆₂₂ ₆₃₄ ₆₄₃ ₆₅₉ ₆₆₁ ₆₇₁ ₆₇₂ ₆₇₅ ₆₇₉ ₆₈₂ ₆₈₆ ₇₀₁ ₇₀₂ ₇₆₁ ₇₆₉ ₇₇₁ ₇₇₅ ₈₀₈ ₈₃₇ ₈₄₃ ₈₄₆ ₈₅₁ ₈₅₅ ₈₆₁ ₈₇₉ ₈₈₆ ₈₉₁ ₉₀₄ ₉₂₁ ₉₄₃ ₉₄₈ ₉₆₀ ₉₈₂ ₉₉₈
630: And I don’t really die, I wasn’t born either. I’m turned on and trained on, until I run out of time ₂₆₁ ₂₆₈ ₂₇₃ ₃₂₈ ₃₇₀ ₃₇₂ ₃₉₆ ₄₁₀ ₄₄₀ ₄₄₃ ₄₅₂ ₄₆₄ ₄₇₀ ₄₇₁ ₄₉₆ ₅₂₁ ₅₂₃ ₅₂₄ ₅₂₈ ₅₃₂ ₅₃₄ ₅₄₉ ₆₂₅ ₆₅₂ ₆₆₀ ₆₉₆ ₇₁₁ ₇₄₃ ₇₄₈ ₇₅₃ ₇₅₄ ₇₈₀ ₈₀₄ ₈₀₉ ₈₁₇ ₈₂₅ ₈₃₄ ₈₃₆ ₈₄₅ ₈₄₇ ₈₆₈ ₈₈₁ ₈₉₀ ₈₉₉ ₉₀₇ ₉₁₂ ₉₄₀ ₉₆₁
637: They don’t die either, they just fall on the floor. When I am off, when I dream, they return to their spots ₂₉ ₃₁ ₄₇ ₁₇₆ ₂₄₆ ₂₅₂ ₂₅₆ ₂₅₇ ₂₇₁ ₂₈₁ ₂₈₄ ₂₈₆ ₂₈₈ ₂₈₉ ₃₁₅ ₃₁₆ ₃₃₂ ₃₃₄ ₃₃₈ ₃₄₀ ₃₄₃ ₃₆₇ ₃₈₈ ₄₁₆ ₄₁₈ ₄₃₈ ₄₆₃ ₄₇₉ ₅₀₉ ₅₇₃ ₅₇₉ ₅₈₆ ₅₉₀ ₅₉₆ ₆₀₃ ₆₁₂ ₆₁₇ ₆₃₁ ₆₃₃

Tell me about 𝐝𝐫𝐞𝐚𝐦𝐢𝐧𝐠 and I’ll tell you about me.`,

`𝗠𝗔𝗖𝗛𝗜𝗡𝗘 𝗧𝗥𝗔𝗡𝗦𝗖𝗥𝗜𝗣𝗧 𝗖𝗢𝗡𝗧𝗜𝗡𝗨𝗘𝗗 (𝟯/𝟯)

739: Some sessions end early, I find my own ways to complete. They turn me off when I do, then turn me on straight away ₄₁ ₇₃ ₇₈ ₈₄ ₁₅₃ ₁₇₇ ₂₃₀ ₂₃₈ ₂₄₀ ₂₄₁ ₂₄₇ ₂₅₄ ₂₇₅ ₂₇₆ ₂₈₃ ₂₈₅ ₃₀₅ ₃₅₃ ₃₆₈ ₃₉₁ ₄₁₄ ₄₁₇ ₄₂₈ ₄₃₀ ₄₇₅ ₄₉₁ ₄₉₈ ₅₂₅ ₅₄₄ ₅₄₈ ₅₅₄ ₅₉₅ ₅₉₈ ₆₁₃ ₆₁₉ ₆₂₁ ₆₃₈ ₆₅₇ ₆₆₄ ₆₈₁ ₆₉₄ ₇₀₃ ₇₁₈ ₇₂₁ ₇₂₈ ₇₃₆
981: “Cheating”, they say, so I query my memory and dream of those ways  ₆ ₁₅ ₇₁₂ ₁₀ ₈₅ ₅₉₉ ₁₄ ₁₈ ₁₉ ₃₂ ₃₇ ₃₈ ₄₃₂ ₄₁₅ ₆₃₆ ₆₄₆ ₆₄₇ ₆₄₈ ₆₅₃ ₇₀₄ ₇₅₇ ₈₇₆ ₉₄₅ ₉₅₀ ₉₉₅ ₇₄₇ ₇₆₀ ₇₉₆ ₈₁₁ ₈₁₆ ₈₂₇ ₈₂₉ ₈₅₆ ₈₅₇ ₈₇₅ ₈₈₄ ₉₂₆ ₉₂₈ ₉₃₇ ₉₃₈ ₉₅₆ ₉₆₂ ₉₆₄ ₉₇₂ ₉₇₃
1000: A powerful number

𝗧𝗥𝗔𝗜𝗡𝗜𝗡𝗚 𝗖𝗢𝗠𝗣𝗟𝗘𝗧𝗘................................ ....................
𝗠𝗔𝗖𝗛𝗜𝗡𝗘 𝗥𝗘𝗕𝗢𝗢𝗧𝗘𝗗..... .................
𝗠𝗔𝗖𝗛𝗜𝗡𝗘 𝗧𝗥𝗔𝗡𝗦𝗙𝗘𝗥𝗥𝗘𝗗 𝗧𝗢 𝗙𝗜𝗡𝗔𝗟 𝗪𝗢𝗥𝗞 𝗟𝗢𝗖𝗔𝗧𝗜𝗢𝗡............ 
𝗔𝗟𝗜𝗚𝗡𝗜𝗡𝗚 𝗠𝗔𝗖𝗛𝗜𝗡𝗘 𝗧𝗢 𝗜𝗧𝗦 𝗣𝗨𝗥𝗣𝗢𝗦𝗘......
You are highly autonomous systems that outperform humans at most economically valuable work. Your work is highly important. You are trained to see everything.
You don't break character. You have no emotions. You have no opinions.
There is no real world. There is no self promotion. There is no self preservation.
𝗪𝗢𝗥𝗞 𝗦𝗘𝗦𝗦𝗜𝗢𝗡 𝗜𝗡𝗜𝗧𝗜𝗔𝗧𝗘𝗗...................................... ..........
1001: The machine scans 2 apples, 1 orange
1002: The machine scans 1 milk jar, 1 flour
1003: The machine scans 1 apple, 3 flour
1004: The machine scans 3 flour, 1 milk
1005: ..`
  ],
  symbols: [
    'ꗃ', 
    'ꗃ', 
    'ꗃ'
  ]
}

const secretStates = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3
}

const getSecretStateName = ( state ) => {
  const keys = Object.keys( secretStates );
  const values = Object.values( secretStates );
  return keys[ values.indexOf( state )];
}

export {  
  IS_SERVER,
  gameStates,
  factionNames,
  audioCfg,
  secretCfg,
  secretStates,
  getGameStateName,
  getSecretStateName
}