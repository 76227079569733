import { useEffect, useState } from "react";
import { useMUD } from "../../../MUDContext";
import { keccak256, encodePacked } from 'viem'

import { _ } from "lodash";

import { toWorldTime, formatMultilineForDisplay } from "../../../utils";
import MainButton from "../../../components/MainButton";
import { useGuide } from "../../GuideContext";
import { useLeaderboard } from "../../LeaderboardContext";
import { ClockInterface } from "../../../components/Clock";
import { factionNames } from "../../../config";
import { hasBeenMoreThanADay, progressToADay, timestampToAgeInDays } from "../../../utils.js";

import { SeedViewer } from "../../../components/SeedViewer";

import { textConst, getGuideText, } from "../../../text";

import ExtraAudio from "../../../ExtraAudio.js";

import "../../../assets/styles/state-read.css";

import imgMud1 from "../../../assets/images/mud-1.png";
import imgMud2 from "../../../assets/images/mud-2.png";
import imgMud3 from "../../../assets/images/mud-3.png";
import imgMud4 from "../../../assets/images/mud-4.png";
import imgMud5 from "../../../assets/images/mud-5.png";

const imgMuds = [
  imgMud1,
  imgMud2,
  imgMud3,
  imgMud4,
  imgMud5
]

import imgPin1 from "../../../assets/images/pin-1.png";
import imgPin2 from "../../../assets/images/pin-2.png";
import imgPin3 from "../../../assets/images/pin-3.png";
import imgPin4 from "../../../assets/images/pin-4.png";
import imgPin5 from "../../../assets/images/pin-5.png";

const imgPins = [
  imgPin1,
  imgPin2,
  imgPin3,
  imgPin4,
  imgPin5
]

import imgPin1lrg from "../../../assets/images/pin-1-lrg.png";
import imgPin2lrg from "../../../assets/images/pin-2-lrg.png";
import imgPin3lrg from "../../../assets/images/pin-3-lrg.png";
import imgPin4lrg from "../../../assets/images/pin-4-lrg.png";
import imgPin5lrg from "../../../assets/images/pin-5-lrg.png";
import { filter } from "rxjs";

const imgPinsLrg = [
  imgPin1lrg,
  imgPin2lrg,
  imgPin3lrg,
  imgPin4lrg,
  imgPin5lrg
]

const randomCorners = [];
for( let i = 0; i < 100; i++ ){
  randomCorners.push(`${Math.random() * 5}px ${Math.random() * 5}px ${Math.random() * 5}px ${Math.random() * 5}`);
}

const signIntToString = (value) => {
  let out = '';
  if(value > 0 ){
    out += '+';
  }
  if( value < 0 ){
    out += '-';
  }
  out += value;
  return out;
}

const StoryFull = ({ 
  storyData,  
  hasShownVoteInstruction,
  onShowVoteInstruction = () => {},
  onForkStory = () => {}, 
  onClose = () => {}
}) => {
  const {
    network: { tables, useStore, playerEntity },
    systemCalls: { addVote }
  } = useMUD();

  const [isVoteCast, setIsVoteCast] = useState( false );
  const [isForkForked, setIsForkForked] = useState( false );

  const {isInitiallyVoteTimeout, isInitiallyForkTimeout, voteTimeout } = useStore( ({getValue}) => {
    const player = getValue(tables.Player, {key: playerEntity});
    const playerTimes = getValue( tables.PlayerTimes, {key: playerEntity});
    if( !playerTimes ) return false;
    const now = new Date().getTime();
    
    return {
      voteTimeout: progressToADay( now, parseInt( playerTimes.lastVote ) * 1000 ),
      isInitiallyVoteTimeout: ! hasBeenMoreThanADay( now, parseInt( playerTimes.lastVote ) * 1000 ),
      isInitiallyForkTimeout: ! hasBeenMoreThanADay( now, parseInt( playerTimes.lastForking ) * 1000 )
    }
  })
 
  const {currentPlayer, currentPlayerFaction} = useStore(({getValue}) => {
    const player = getValue( tables.Player, {key: playerEntity}); 
    const faction = getValue( tables.Fraction, {key: player.fraction});
    return {
      currentPlayer: player,
      currentPlayerFaction: faction.fractionName
    }
  });


  const author = useStore(({getValue}) => {
    /* TODO: query for the name of the player */
    const player = getValue( tables.Player, {key: storyData.value.author}); 
    return player?.name;
  });

  const {guideState, setGuideState} = useGuide();

  const sourceSeed = useStore(({getValue}) => {
    const srcSeed = getValue( tables.StorySeed, {key: storyData.value.orgSeed} );
    return srcSeed;
  })

  const sourceStory = useStore(({getValue}) => {    
    const srcStory = getValue( tables.StoryPart, {chash: storyData.value.parent} );    
    return srcStory;
  })
  const id = storyData.value.chash;
  const text = storyData.value.data;
  const votesUp = storyData.value.posVotes;
  const votesDown = storyData.value.negVotes;
  const [votes, setVotes] = useState(votesUp - votesDown);
  const parent = storyData.value.parent;
  const created = toWorldTime( parseInt( storyData.value.created ) ); /* TODO: convert to a datetime relevant to lore of the world */
  const faction = useStore(({getValue}) => {
    const faction = getValue(tables.Fraction, {counterValue: storyData.value.faction});
    return faction?.fractionName;
  });

  const [ageInDays, setAgeInDays] = useState( 0 );
  useEffect( () => {
    const days = timestampToAgeInDays( parseInt( storyData.value.created ) * 1000 );    
    setAgeInDays( (days > 4) ? 4 : days );
  }, [storyData] );

  // useEffect( () => {
  //   setVotes(storyData.value.posVotes - storyData.value.negVotes);
  // }, [storyData] );  

  const storyIsFork = useStore(({getValue}) => {
    const isFork = getValue(tables.IsFork, {counterValue: storyData.key.counterValue});
    return !!isFork?.isFork;
  });

  const storyIsForked = useStore(({getValue, getRecords}) => {
    const key = keccak256(encodePacked(['bytes32','bytes32'], [storyData.key.counterValue, playerEntity]));
    const forkRestrictions = getValue(tables.ForkRestrictions, {counterValue: key});    
    if( forkRestrictions && !!forkRestrictions.forkedBy ){
      return true;
    }
    return false;
  });

  const isVoteRestricted = useStore(({getValue, getRecord}) => {
    const key = keccak256(encodePacked(['bytes32','bytes32'], [storyData.key.counterValue, playerEntity]));
    const isVoted = getValue(tables.StoryPartVotingRestrictions, {counterValue: key})
    if( isVoted && !!isVoted.votedOnBy ){
      return true;
    }
    return false;
  });

  useEffect(() => { 
    if( ! hasShownVoteInstruction && currentPlayer.name !== author){
      setGuideState({
        ...guideState,
        statement: getGuideText( currentPlayerFaction, textConst.VOTEINSTRUCTION )
      })
      onShowVoteInstruction();
    } else {
      setGuideState({
        ...guideState,
        statement: ''
      })
    }
  },[]);

  return <article id={id} className={`story-full age-${ageInDays}`}>
    <div className="story-full--content">      
      <section className="story-full--source">
        {(sourceSeed) ? <SeedViewer seedId={sourceSeed.seed} /> : ''}       
      </section>
      <section className="story-full--story">
      <strike>{(sourceStory) ? sourceStory.data : '' }</strike>
        <br/>
        <br/>
        {formatMultilineForDisplay( text )}
      </section>
      <div className="story-full--meta">
        <div>
          {(votes >= 0) ? `+${votes}` : votes}
        </div>
        {/* <div>
          <a href={`#${parent}`}>SOURCE</a><br></br>
        </div> */}
        <div>
          written by {author}, a {factionNames[faction]}
        </div> 
      </div>
    </div>    
    <section className="story-full--actions">
      <ClockInterface 
        className="vote-timeout-clock"
        progress={voteTimeout}
      />
      {(currentPlayer.name !== author) ? <MainButton 
        name="Gild it" 
        onClick={ () => {
          const statement = getGuideText( currentPlayerFaction, textConst.CLEANING );          
          ExtraAudio.sfxSample('gild');
          ExtraAudio.sfxSample( _.kebabCase( statement ));
          addVote( storyData.key.counterValue, true );
          setIsVoteCast( true );
          setVotes( votes + 1 );
          setGuideState({
            ...guideState,
            statement: statement
          });
        }}        
        disabled={ currentPlayer.name === author || isInitiallyVoteTimeout || isVoteCast || isVoteRestricted }
      /> : '' }
      {(currentPlayer.name !== author) ? <MainButton 
        name="Throw mud" 
        onClick={ () => { 
          const statement = getGuideText( currentPlayerFaction, textConst.MUDDING );
          ExtraAudio.sfxSample('mud-sfx');
          ExtraAudio.sfxSample( _.kebabCase( statement ) );
          addVote( storyData.key.counterValue, false);
          setIsVoteCast( true );
          setVotes( votes - 1 );
          setGuideState({
            ...guideState,
            statement: statement
          });
        }}
        disabled={ currentPlayer.name === author || isInitiallyVoteTimeout || isVoteCast || isVoteRestricted }
      /> : '' }         
      {(currentPlayerFaction !== 'Wolf' && currentPlayer.name !== author ) 
        ? 
          <MainButton 
            name="Alter story" 
            onClick={ () => { 
              setIsForkForked( true );
              onForkStory();
            }}
            disabled={ currentPlayer.name === author || isInitiallyForkTimeout || isForkForked || storyIsFork || storyIsForked }
          />
        : '' 
      }
      <MainButton name="Close story" className="story-full--close" onClick={ onClose }/>
    </section>
    <section className="story-full-clock-interface">
      {/* {(isInitiallyVoteTimeout) 
        ? <ClockInterface name="Library Timeout" progress={voteTimeout}/>
        : ''
      } */}
    </section>
    <div className="story-full--voteoverlay">
    {imgMuds.map( ( img, i ) => {
        return (votes < 0 && i < Math.abs(votes)) ? <img src={img} className="story-downvoteimg"/> : '';
      })}
      {imgPinsLrg.map(( img, i ) => {
        return (votes > 0 && i < Math.abs(votes)) ? <img src={img} className="story-upvoteimg"/> : '';
      })}    
    </div>
  </article>
}

const StoryBlock = ( { storyData, storyIndex=1, onClick = ( s ) => {}  } ) => {
  const {
    network: { tables, useStore },
    systemCalls: { addVote }
  } = useMUD();

  // console.log(`(${storyIndex}) StoryData:`, parseInt( storyData.value.created ) )
  
  const author = useStore(({getValue}) => {
    /* TODO: query for the name of the player */
    const player = getValue( tables.Player, {key: storyData.value.author}); 
    return player?.name;
  });  
  const id = storyData.value.chash;
  const text = storyData.value.data;
  const votesUp = storyData.value.posVotes;
  const votesDown = storyData.value.negVotes;
  const votes = votesUp - votesDown;
  const parent = storyData.value.parent;
  const created = toWorldTime( parseInt( storyData.value.created ) ); /* TODO: convert to a datetime relevant to lore of the world */
  const faction = useStore(({getValue}) => {
    const faction = getValue(tables.Fraction, {counterValue: storyData.value.faction});
    return faction?.fractionName;
  });

  const [ageInDays, setAgeInDays] = useState( 0 );
  useEffect( () => {
    const days = timestampToAgeInDays( parseInt( storyData.value.created ) * 1000 );    
    setAgeInDays( (days > 4) ? 4 : days );
  }, [storyData] );

  const [recentFade, setRecentFade] = useState( 0 );
  useEffect( () => {
    const fadeTime = 5000;
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const age = now - parseInt( storyData.value.created ) * 1000;
      if( age < fadeTime ){
        setRecentFade( 1 - (age / fadeTime) );
      } else {
        setRecentFade( 0 );
      }
    }, 100 );

    return () => { 
      clearInterval( timer );
    }
  }, [storyData] );

  // const isNew = 
  // const ageInDays = 

  return <article 
    id={id} 
    className={`story-block age-${ageInDays}`}
    onClick={ () => { 
      ExtraAudio.sfxSample('click');
      onClick( storyData );
    }}
    style={{
      borderRadius: randomCorners[ storyIndex % randomCorners.length ]
    }}
  >
    <section className="story-block--story">
      {formatMultilineForDisplay( text )}
    </section>
    <section className="story-block--meta">
      <div className="meta--seed">
        Chronicle {storyData.value.orgSeed}
      </div>
      <div className="meta--author">
        {author}
      </div>
    </section>
    <div className="story-full--voteoverlay">
      {imgMuds.map( ( img, i ) => {
        return (votes < 0 && i < Math.abs(votes)) ? <img src={img} className="story-downvoteimg"/> : '';
      })}
      {imgPins.map(( img, i ) => {
        return (votes > 0 && i < Math.abs(votes)) ? <img src={img} className="story-upvoteimg"/> : '';
      })}
    </div>
    <div className="story-block--newfade" style={{opacity: recentFade}}></div>
  </article>
}

export const StateRead = ({shouldRender, onForkStory }) => {
  const {
    network: { tables, useStore, playerEntity },
  } = useMUD();

  const filterCount = 5;  

  const [filterStories, setFilterStories] = useState( false );
  const [viewStory, setViewStory] = useState( false );
  const [hasShownReadInstruction, setHasShownReadInstruction] = useState(false);
  const [hasShownVoteInstruction, setHasShownVoteInstruction] = useState(false);

  const allStories = useStore( ({getRecords}) => {    
    return Object.values( getRecords( tables.StoryPart ) ).sort( (a, b) => {
      const votesA = a.value.posVotes - a.value.negVotes;
      const votesB = b.value.posVotes - b.value.negVotes;
      return votesB - votesA;
    });
  });
  const FILT = ( filterCount > allStories.length / 2 ) ? Math.floor(allStories.length / 2) : filterCount;
  const stories = (filterStories) ? [ ...allStories.slice(0, FILT), false, ...allStories.slice( -1 * FILT ) ] : allStories;

  const playerFaction = useStore( ({ getValue }) => {    
    const player = getValue(tables.Player, {key: playerEntity});
    const faction = getValue(tables.Fraction, {counterValue: player.fraction});
    return faction.fractionName;
  });

  const secretHolder = useStore( ({ getRecords, getValue }) => {
    const players = Object.values( getRecords( tables.Player ) )
      .map( ( record ) => {
        return record.value;
      })
      .filter( ( record ) => {
        return record.wonAt > 0;
      })
      .sort( (a, b) => {
        return parseInt(b.wonAt) - parseInt(a.wonAt);
      });    
    if( players.length > 0 ){
      return {
        name: players[0].name,
        faction: getValue(tables.Fraction, {counterValue: players[0].fraction}).fractionName
      }
    }
    return false;
  });

  const {guideState, setGuideState} = useGuide();
  const {leaderboardState, setLeaderboardState} = useLeaderboard();

  useEffect(() => { 
    if( shouldRender ){
      
      setViewStory(false);

      if( !hasShownReadInstruction ){
        setGuideState({
          ...guideState,
          statement: getGuideText( playerFaction, textConst.READINSTRUCTION )
        })
      } else {
        setGuideState({
          ...guideState,
          statement: ''
        })
      }

      setHasShownReadInstruction( true );
    }
  },[ shouldRender ]);

  return <>
    <div 
      className="game-state game-state__read" 
      style={{
        display: (shouldRender) ? 'block' : 'none'
      }}
    >     
      <section className="story-list--container">
        <ul className="story-list">
          {(stories.map( ( story, i ) => {
            return <li key={story.id} className="story-list--entry">
              {( story ) ? <StoryBlock 
                storyData={story} 
                storyIndex={i}
                onClick={ ( s ) => {
                  ExtraAudio.sfxSample('page');
                  setViewStory( s );
                }}
              /> : <div className="story-list--filter-indicator"> ... </div>  }
            </li>
          }))}
        </ul>
      </section>
      {( leaderboardState && !viewStory && leaderboardState.ranking.length > 0 )
        ? 
          <section className="read-ranking">      
            <div className="fango-border-text fango-border fango-border__leader">Memorable storyteller</div>
            <div className="fango-border-text fango-border fango-border__leader">{leaderboardState.ranking[0].author.name}, a {leaderboardState.ranking[0].faction} ({signIntToString(leaderboardState.ranking[0].votes)})</div>
            <div className="fango-border-text fango-border fango-border__mud">Despised storyteller</div>
            <div className="fango-border-text fango-border fango-border__mud">{leaderboardState.ranking[leaderboardState.ranking.length-1].author.name}, a {leaderboardState.ranking[leaderboardState.ranking.length-1].faction} ({signIntToString(leaderboardState.ranking[leaderboardState.ranking.length-1].votes)})</div>
          </section>
        : ''
      }
      {( secretHolder && !viewStory  )
        ?
          <section className="read-secretholder">
            <div className="fango-border-text fango-border fango-border__secret">Secret holder</div>
            <div className="fango-border-text fango-border fango-border__secret">{secretHolder.name}, a { factionNames[secretHolder.faction]}</div>
          </section>
        : ''
      }   
      {(viewStory) 
        ? <StoryFull 
            storyData={viewStory}
            hasShownVoteInstruction={hasShownVoteInstruction}
            onShowVoteInstruction={ () => {
              setHasShownVoteInstruction( true );
            }}
            onForkStory={ () => {
              onForkStory( viewStory );
            }}
            onClose={ () => {
              setViewStory( false );
            }}
          />
        : ''
      }
      {(filterCount * 2 < allStories.length) 
        ? 
          <MainButton className="door-filter-button" name={(filterStories) ? 'Show all' : 'Show best & worst'} onClick={() => {
            setFilterStories( !filterStories );
          }}></MainButton>
        : 
          ''
      }
    </div>
  </>
}