/*
 * The supported chains.
 * By default, there are only two chains here:
 *
 * - mudFoundry, the chain running on anvil that pnpm dev
 *   starts by default. It is similar to the viem anvil chain
 *   (see https://viem.sh/docs/clients/test.html), but with the
 *   basefee set to zero to avoid transaction fees.
 * - latticeTestnet, our public test network.
 *

 */

// import { defineChain } from 'viem'
// import { foundry } from "viem/chains";

// const fangoFoundry = defineChain({
//   ...foundry,
//   id: 666,
//   name: 'FangoFoundry',  
//   rpcUrls: {
//     default: {
//       http: ['http://127.0.0.1:8545'],
//       webSocket: ['ws://127.0.0.1:8545'],
//     },
//     public: {
//       http: ['http://127.0.0.1:8545'],
//       webSocket: ['ws://127.0.0.1:8545'],
//     },
//   },
// })


import { MUDChain, latticeTestnet, mudFoundry } from "@latticexyz/common/chains";

mudFoundry.rpcUrls.default.http = ['https://fangochain.dmstfctn.net']
mudFoundry.rpcUrls.default.webSocket = ['wss://fangochain.dmstfctn.net']

/*
 * See https://mud.dev/tutorials/minimal/deploy#run-the-user-interface
 * for instructions on how to add networks.
 */
export const supportedChains: MUDChain[] = [mudFoundry, latticeTestnet];
